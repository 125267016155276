import { Language } from './Entities';

// AppUrls are links used throughout the app
export enum AppUrls {
    UTM_PARAMS = '?utm_source=website&utm_medium=social',
    WEBSITE_URL = 'https://nomadzenlife.com',
    MATT_WONG_BASE_URL = '',
    FACEBOOK_PAGE_URL = 'https://www.facebook.com/profile.php?id=61561547390102',
    INSTAGRAM_URL = 'https://www.instagram.com/nomadzenlife',
    TIKTOK_URL = 'https://www.tiktok.com/@nomadzenlife',
    LINKEDIN_URL = 'https://www.linkedin.com/company/nomad-zen-life',
    HASHICORP= 'https://www.hashicorp.com/',
    TERRAFORM_PROVIDERS = 'https://registry.terraform.io/browse/providers',
    TERRAFORM_DOWNLOAD = 'https://learn.hashicorp.com/terraform/getting-started/install.html',
    AWS_CLI_DOWNLOAD = 'https://docs.aws.amazon.com/cli/latest/userguide/install-cliv2.html',
    TERRAFORM_BACKEND= 'https://www.terraform.io/docs/backends/index.html',
    REACT_SYNTAX_HIGHLIGHTER = 'https://www.npmjs.com/package/react-syntax-highlighter',
    REACT_MARKDOWN = 'https://www.npmjs.com/package/react-markdown',
    ORYX_CONFIGURER = 'https://configure.zsa.io/',
    SIDE_HUSTLE_STACK = 'https://sidehustlestack.co/',
    MAGIC_EDEN_MARKETPLACE = 'https://magiceden.io/',
    CORAL_CUBE_MARKETPLACE = 'https://coralcube.io/',
    OPENSEA_MARKETPLACE = 'https://opensea.io/',
    FRACTAL_MARKETPLACE = 'https://www.fractal.is/',
    SOLANART_MARKETPLACE = 'https://solanart.io/',
    SOLSEA_MARKETPLACE = 'https://solsea.io/',
    InstallZSH = 'https://github.com/ohmyzsh/ohmyzsh/wiki/Installing-ZSH',
    WhatIsOhMyZsh = 'https://ohmyz.sh/',
    WhatIsCurl = 'https://curl.se/',
    WhatIsWGet = 'https://www.gnu.org/software/wget/',
    ZSHPluginsList = 'https://github.com/ohmyzsh/ohmyzsh/wiki/Plugins-Overview',
    ZSHThemesList = 'https://github.com/ohmyzsh/ohmyzsh/wiki/Themes',
}

// Amazon Affiliate Links
export enum AmazonAffiliateLinks {
    // gadgets
    CherryMxBrownSwitches = 'https://amzn.to/3GExBeY',
    LaptopStand = 'https://amzn.to/3GEUv62',
    YetiMicrophone = 'https://amzn.to/3BSMNCN',
    NexigoWebcam = 'https://amzn.to/3UZtNcv',
    KinesisEdge = 'https://amzn.to/3XnOoIZ',
    LogitechErgo = 'https://amzn.to/3XnwHcB',
    RazorHuntsman = 'https://amzn.to/3VfZ7nD',
    DuckyOne = 'https://amzn.to/3ADdb22',
    // books
    CrackingTheCodingInterview = 'https://amzn.to/3XoIAz1',
    ThePragmaticProgrammer = 'https://amzn.to/3GItNcu',
    CleanCode = 'https://amzn.to/3U0dMBC',
    ZeroToOne = 'https://amzn.to/3i4Dsjk',
    TheHiddenLanguageOfComputerHardware = 'https://amzn.to/3B0MyV9',
    // Keycaps
    HyperX = 'https://amzn.to/3hTRwfp',
    DSA ='https://amzn.to/3XupqrI',
    RazerDoubleShot = 'https://amzn.to/3GDHXeU',
    PBT = 'https://amzn.to/3ACy1yK',
    XDA = 'https://amzn.to/3TZuNMq',
}
// Service Links
export enum UsefulServices {
    AlgoExpert = 'https://www.algoexpert.io/',
    GrokkingSystemDesign = 'https://github.com/Jeevan-kumar-Raj/Grokking-System-Design',
    LevelsFYI = 'https://www.levels.fyi/',
    TeamBlind = 'https://www.teamblind.com/',
    Notion = 'https://www.notion.so/',
    AITravelGuide = 'https://chatgpt.com/g/g-uJJvZK3Wo-traveler-hotel-guide'
}

export enum Gadgets {
  ZSAErgodoxMoonlander = 'https://www.zsa.io/moonlander',
}

export enum ReactGACategory {
  Video = 'Video',
  Button = 'Button',
  Form = 'Form',
  Download = 'Download',
}

export enum ReactGAActions {
  Play = 'Play',
  Submit = 'Submit',
  Click = 'Click',
  Download = 'Download',
}

export enum ReactGALabels {
 HomePageLink = 'HomePageLink',
 BlogPageLink = 'BlogPageLink',
 AboutPageLink = 'AboutPageLink',
 BlogPostLink = 'BlogPostLink',
 FAQPageLink = 'FAQPageLink',
 TripPlannerLink = 'TripPlannerLink',
 InteractiveMapLink = 'InteractiveMapLink',
 JobBoardLink = 'JobBoardLink',
 StateOfRemoteWork2024Link = 'StateOfRemoteWork2024Link',
 AITravelGuideLink = 'AITravelGuideLink',
}

export enum ReactGALocationDimension {
  Footer = 'Footer',
  Header = 'Header',
  BlogPost = 'BlogPost',
  JobBoard = 'JobBoard',
  BlogPostList = 'BlogPostList',
  BlogPostContent = 'BlogPostContent',
  BlogPostAuthor = 'BlogPostAuthor',
  BlogPostDate = 'BlogPostDate',
  BlogPostTag = 'BlogPostTag',
}

export const RouteToLabelMap = {
  '/'                     : ReactGALabels.HomePageLink,
  '/about'                : ReactGALabels.AboutPageLink,
  '/faq'                  : ReactGALabels.FAQPageLink,
  '/blog'                 : ReactGALabels.BlogPageLink,
  '/blog/:slug'           : ReactGALabels.BlogPostLink,
  '/tools/trip-planner'   : ReactGALabels.TripPlannerLink,
  '/tools/interactive-map': ReactGALabels.InteractiveMapLink,
  '/tools/job-board'      : ReactGALabels.JobBoardLink,
};

export const BlogTagHighlights = [
    'keyboards',
    'interviews',
    'hiring',
    'side-hustles',
    'crypto',
];

export const LessonTagHighlights = [
    'programming',
];

// ETC
export const MAIL_TO_EMAIL: string = 'mailto:mwong17@cmc.edu';
export const ENGLISH_LANG: string = 'english';
export const JAPANESE_LANG: string = 'japanese';

export const LANGUAGE_SET: Array<Language> = [
    {
        name     : 'language.name.english',
        shortName: 'language.short_name.english',
        code     : 'en',
    },
    {
        name     : 'language.name.japanese',
        shortName: 'language.short_name.japanese',
        code     : 'ja',
    },
];

export const getLanguageByCode = (supportedLanguages: Language[], code: string | undefined | null): Language | undefined => {
    if (code) {
        return supportedLanguages.find((language: Language) => {
            return language.code === code;
        });
    }

    return undefined;
};

export const DEFAULT_LANGUAGE = ((): Language => {
    // First, try to find the browser's default language and see if it is one of the languages that we support.
    const browserLanguages: string[] = [];

    // Go though all the languages that we support and find one that has a language code that matches the browser's languages
    const browserLanguageCode: string | undefined = browserLanguages.find(
        (code: string): boolean => {
            const [languageCode] = code.includes('-') ? code.split('-') : code.split('_');
            return getLanguageByCode(LANGUAGE_SET, languageCode) !== undefined;
        },
    );

    // The default language will be either the browser language, or English, or the FIRST language in the list which ever match first
    const result: Language = getLanguageByCode(LANGUAGE_SET, browserLanguageCode)
      || getLanguageByCode(LANGUAGE_SET, 'en')
      || LANGUAGE_SET[0];

    return result;
})();

export const postDateTemplate: any = {
    weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
};

export const markdown = `
~~~hcl
terraform
Usage: terraform [-version] [-help] <command> [args]

The available commands for execution are listed below.
The most common, useful commands are shown first, followed by
less common or more advanced commands. If you're just getting
started with Terraform, stick with the common commands. For the
other commands, please read the help and docs before usage.
~~~
  `;

export const awsCreds = `
~~~bash
cat ~/.aws/credentials
`;

export const terraformSetup = `
~~~hcl
terraform {
  required_version = "~> 0.13.0"

  backend "s3" {
    bucket = "{aws_account}-{env}-tform"
    region = "us-west-2"
    key    = "terraform/{service}/{env}.tfstate"
  }
  provider "aws" {
    version = "~> 2.0"
    region  = "us-west-2"
  }
  
  locals {
    environment  = "{target environment}"
    project_name = "{project/service namee}"
    {any other var} = ""
  }
}
~~~
`;

export const terraformResource = `
~~~hcl
resource "aws_ecs_service" "{service}" {
  name        = "{service}"
  cluster     = aws_ecs_cluster.default.arn
  launch_type = "{EC2 or FARGATE}"

  task_definition = "{task definition id}"

  desired_count = 0

  network_configuration {
    subnets          = var.subnet_ids
    security_groups  = var.security_group_ids_internal
    assign_public_ip = true
  }

  depends_on = [aws_ecs_cluster.default, aws_ecs_task_definition.{service}]

  lifecycle {
    ignore_changes = [
      desired_count
    ]
  }
}
~~~
`;

export const terraformInit = `
~~~bash
terraform init               
Initializing modules...

Initializing the backend...

Initializing provider plugins...
- Using previously-installed hashicorp/aws v2.70.0
- Using previously-installed hashicorp/template v2.2.0

The following providers do not have any version constraints in configuration,
so the latest version was installed.

To prevent automatic upgrades to new major versions that may contain breaking
changes, we recommend adding version constraints in a required_providers block
in your configuration, with the constraint strings suggested below.

* hashicorp/template: version = "~> 2.2.0"

Terraform has been successfully initialized!

You may now begin working with Terraform. Try running "terraform plan" to see
any changes that are required for your infrastructure. All Terraform commands
should now work.

If you ever set or change modules or backend configuration for Terraform,
rerun this command to reinitialize your working directory. If you forget, other
commands will detect it and remind you to do so if necessary.
~~~
`;

export const noChanges = `
~~~bash
No changes. Infrastructure is up-to-date.

This means that Terraform did not detect any differences between your
configuration and real physical resources that exist. As a result, no
actions need to be performed.
~~~
`;
export const reactWorkflowFile = `
~~~yml
name: Building and pushing frontend builds to S3 and invalidating Cloudfront for dev release

on:
  push:
    branches:
      - master
    paths:
      - {directory to trigger}/**

env:
  AWS_DEFAULT_REGION: us-west-2 #example (change to your region)

jobs:
  frontend-dev-build-and-push:
    env:
      AWS_ACCOUNT_ID: {{ secrets.AWS_ACCOUNT_ID }}
      AWS_ACCESS_KEY_ID: {{ secrets.AWS_ACCESS_KEY_ID }}
      AWS_SECRET_ACCESS_KEY: {{ secrets.AWS_SECRET_ACCESS_KEY }}
      {OTHER VARS}
      CI: false #true more strict if you have compilation warnings
      CF_DISTRIBUTION_ID: {Cloudfront distribution ID for invalidation}

    name: Create build and push
    runs-on: ubuntu-latest
    steps:
      - name: Checkout myrepo/core master # default is master
        uses: actions/checkout@v2

      - name: Use Node.js 12.x
        uses: actions/setup-node@v1
        with:
          node-version: 12.x

      - name: Install dependencies and create build
        working-directory: {directory which holds React code}
        run: |
          npm ci && npm run build
      - name: Push build to S3
        working-directory: {directory which holds React code}
        run: |
          aws s3 sync ./build/ s3://directory/{{ github.sha }} --acl public-read --exact-timestamps --delete
      - name: Get distribution configuration and update origin path
        run: |
            aws cloudfront get-distribution-config --id {CF_DISTRIBUTION_ID} > cf_config.json 
            JQ_QUERY=".DistributionConfig.Origins.Items[0].OriginPath = "/{{ github.sha }}"" 
            jq "$JQ_QUERY" < cf_config.json > updated.json 
            CFDIST_ETAG=$(jq -r '.ETag' < cf_config.json) 
            jq .DistributionConfig < updated.json > new-dist-config.json 
            cat new-dist-config.json 
            aws cloudfront update-distribution --id {CF_DISTRIBUTION_ID} --distribution-config file://new-dist-config.json --if-match $CFDIST_ETAG
      - name: Invalidate CloudFront Cache
        run: |
          aws cloudfront create-invalidation --distribution-id {CF_DISTRIBUTION_ID} --paths "/*"
~~~
`;

export const golangWorkflowFile = `
~~~yml
name: Building and pushing backend image to ECR, and uploading deployment file for dev release

on:
  push:
    branches:
      - master
    paths:
      - {directory to invoke trigger}/**

env:
  DOCKERFILE_PATH: {Dockerfile path}
  ECR_REPOSITORY_NAME: {the name of the ECR repository }
  AWS_DEFAULT_REGION: us-west-2

jobs:
  api-dev-build-and-push:
    env:
      AWS_ACCOUNT_ID: {{ secrets.DEV_AWS_ACCOUNT_ID }}
      AWS_ACCESS_KEY_ID: {{ secrets.DEV_AWS_ACCESS_KEY_ID }}
      AWS_SECRET_ACCESS_KEY: {{ secrets.DEV_AWS_SECRET_ACCESS_KEY }}

    name: Building images and pushing
    runs-on: ubuntu-latest
    steps:
      - name: Checkout {organization}/{repository name} master
        uses: actions/checkout@v2

      - name: Obtain access to ECR
        run: |
          $( aws ecr get-login --no-include-email )
      - name: Build and tag the images
        run: |
          for SERVICE in $SERVICES; do
            REPOSITORY={ECR_REPOSITORY_NAME}
            docker build \
              --build-arg GITHUB_TOKEN={{ secrets.COMMON_GITHUB_TOKEN }} \
              --build-arg VERSION={{ github.sha }} \
              -t $AWS_ACCOUNT_ID.dkr.ecr.$AWS_DEFAULT_REGION.amazonaws.com/$REPOSITORY:{{ github.sha }} \
              -t $AWS_ACCOUNT_ID.dkr.ecr.$AWS_DEFAULT_REGION.amazonaws.com/$REPOSITORY:latest \
              -f $DOCKERFILE_PATH/Dockerfile .
          done
      - name: Push built images to ECR
        run: |
          for SERVICE in $SERVICES; do
            REPOSITORY={ECR_REPOSITORY_NAME}
            docker push $AWS_ACCOUNT_ID.dkr.ecr.$AWS_DEFAULT_REGION.amazonaws.com/$REPOSITORY:{{ github.sha }}
            docker push $AWS_ACCOUNT_ID.dkr.ecr.$AWS_DEFAULT_REGION.amazonaws.com/$REPOSITORY:latest
          done
      - name: Push deployment file to S3
        run: |
          echo {{ github.sha }} > revision.txt
          zip deploy.zip revision.txt
          aws s3 cp deploy.zip s3://{S3 repository for holding devops stuff}/deployment/{service}/
~~~
`;

export const reactSyntaxHighlighter = `
~~~tsx
const renderers = {
    code: (content: { language: string; value: string }): React.ReactElement => {
        return (
            <SyntaxHighlighter language={content.language} style={docco} children={content.value} />
        );
    },
};
return <ReactMarkdown renderers={renderers} children={markdown} />
~~~`;
