import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import TailwindHeader from '../components/header/TailwindHeader';
import TailwindHome from '../components/central/TailwindHome';
import TailwindFeatureSection from '../components/feature/TailwindFeatureSection';
import TailwindCTA from '../components/cta/TailwindCTA';
import TailwindNewsLetterSignUp from '../components/sign-up/TailwindNewsletterSignUp';
import { Footer, HelmetComponent } from '../components';
import { siteMetadata } from '../data/siteMetadata';
import { Post } from '../components/blog-post/BlogPostFuncs';
import TailwindBanner from '../components/banner/TailwindBanner';
import {
 ReactGAActions, ReactGACategory, ReactGALabels, ReactGALocationDimension,
} from '../utils/Constants';

export default function Home(posts: Post[]) {

  const history = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
      ReactGA.set({ page: history.pathname });
      ReactGA.send({ hitType: 'pageview', page: history.pathname, title: 'Nomad Zen Life - Embrace a Life of Freedom and Adventure' });
      const dimensions = { dimension1: ReactGALocationDimension.Footer };
      ReactGA.event({
        category: ReactGACategory.Button,
        action  : ReactGAActions.Click,
        label   : ReactGALabels.BlogPageLink,
       ...dimensions,
    });
  }, []);

  return (
    <>
      <HelmetComponent
      title="Nomad Zen Life - Embrace a Life of Freedom and Adventure"
      description="Discover the tools, resources, and community you need to live a fulfilling and adventurous life as a digital nomad. Join Nomad Zen Life today!"
      URL={siteMetadata.siteUrl}
      />
      <TailwindHeader />
      <TailwindHome />
      <TailwindFeatureSection />
      <TailwindNewsLetterSignUp forReport />
      <TailwindBanner />
      <TailwindCTA posts={posts} unsplashPhoto />
      <Footer />
    </>
  );
}
