import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Footer, HelmetComponent } from '../components';
import TailwindHeader from '../components/header/TailwindHeader';
import TailwindNewsLetterSignUp from '../components/sign-up/TailwindNewsletterSignUp';
import { AppUrls } from '../utils/Constants';

const SubscribeForReport = () => {

  const history = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
      ReactGA.set({ page: history.pathname });
      ReactGA.send({ hitType: 'pageview', page: history.pathname, title: 'State of Remote Work 2024 - Nomad Zen Life' });
  }, []);

  return (
    <>
     <HelmetComponent
        title="State of Remote Work 2024 Resource - Nomad Zen Life"
        description="The State of Remote Work 2024 Resource is a comprehensive guide to the remote work landscape. Download the resource to learn about the latest trends, statistics, and insights."
        URL={`${AppUrls.WEBSITE_URL}/state-of-remote-work-2024`}
     />
    <TailwindHeader />
      <div className="pb-32" />
    <TailwindNewsLetterSignUp forReport />

    <Footer />
    </>
  );
};

export default SubscribeForReport;
