import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Footer, HelmetComponent } from '../components';
import TailwindHeader from '../components/header/TailwindHeader';
import TailwindNewsLetterSignUp from '../components/sign-up/TailwindNewsletterSignUp';
import { Post } from '../components/blog-post/BlogPostFuncs';
import BlogPost from '../components/blog-post/BlogPost';
import { AppUrls } from '../utils/Constants';
import { DEFAULT_ROUTE_PATHS } from '../routes/RouteDeclarations';

const BlogPage = (props: Post) => {

  const history = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
      ReactGA.set({ page: history.pathname });
      ReactGA.send({ hitType: 'pageview', page: history.pathname, title: `${props.metadata.metaTitle} - Nomad Zen Life` });
  }, []);

  return (
    <>
    <TailwindHeader />
    <HelmetComponent
        title={`${props.metadata.metaTitle} - Nomad Zen Life`}
        description={props.metadata.metaDescription}
        URL={`${AppUrls.WEBSITE_URL}${DEFAULT_ROUTE_PATHS.BLOG}/${props.slug}`}
    />
    <BlogPost content={props.content} metadata={props.metadata} />
    <TailwindNewsLetterSignUp />
    <Footer />
    </>
  );
};

export default BlogPage;
