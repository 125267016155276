import moment from 'moment';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
import PageTitle from '../page_title/PageTitle';
import { globalUIContext, UIContext } from '../../context/UIContext';
import { Post } from './BlogPostFuncs';
import {
  ReactGAActions,
  ReactGACategory,
  ReactGALabels,
} from '../../utils/Constants';

interface AuthorData {
  name: string;
  bio: string;
  profileImage: string;
}

interface BlogMetadata {
  type: string;
  headerImageName: string;
  createdOn: string;
  title: string;
  description: string;
  metaDescription: string;
  metaTitle: string;
  author: AuthorData;
  slug: string;
  readMoreDisabled?: boolean;
  unsplashPhoto: boolean;
  imagePreviewName: string;
  tags: string[];
}

interface BlogPostProps {
  content: string;
  metadata: BlogMetadata;
}

export default function BlogPost({ content, metadata }: BlogPostProps) {
  const uiContext: UIContext = React.useContext(globalUIContext);
  const dateString = moment(metadata.createdOn)
    .format('MMMM Do YYYY')
    .toString();

  const linkedPosts: Post[] = uiContext.state.posts.filter((post: Post) => {
    for (let i = 0; i < metadata.tags.length; i += 1) {
      if (
        post.metadata.tags.includes(metadata.tags[i])
        && post.metadata.metaTitle !== metadata.metaTitle
      ) {
        return true;
      }
    }
    return false;
  });

  return (
    <div>
      <div className="overflow-hidden bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl  gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-1">
            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-20xl">
                <h2 className="text-base font-semibold leading-7 text-indigo-600">
                  <dd className="items-center gap-x-4">
                    <time dateTime={dateString}>{dateString}</time>
                  </dd>
                </h2>
                <p className="mt-2 text-4xl max-w-lg font-bold tracking-tight text-gray-900 sm:text-4xl">
                  <PageTitle className="">{metadata.title}</PageTitle>
                </p>
                <img
                  src={
                    metadata.unsplashPhoto
                      ? metadata.imagePreviewName
                      : `/${metadata.imagePreviewName}`
                  }
                  alt="Product screenshot"
                  className="lg:float-right ml-4 mb-4 mt-4 lg:max-w-lg rounded-xl shadow-xl ring-1 ring-gray-400/10 md:-ml-4"
                  style={{ width: '100%', height: '100%' }}
                />
                <p
                  className="mt-6 text-xl leading-8 text-gray-600"
                  style={{ textAlign: 'justify' }}
                />
                <div style={{ maxWidth: 'none' }}>
                  <ReactMarkdown className="prose text-xl justify-content: normal max-w-full">
                    {content}
                  </ReactMarkdown>
                  {linkedPosts.length > 0 && (
                    <div className="pt-5 font-bold text-3xl justify-content: normal max-w-full">
                      Related Blog Posts
                      {linkedPosts.map((post: Post) => {
                        return (
                          <div className="flex flex-start justify-between">
                            <Link
                              to={`/blog/${post.metadata.slug}`}
                              onClick={() => {
                                ReactGA.event({
                                  category: ReactGACategory.Button,
                                  action  : ReactGAActions.Click,
                                  label   : ReactGALabels.BlogPostLink,
                                });
                              }}
                              className="hover:no-underline pb-1 pt-1 text-lg font-medium text-indigo-600 hover:text-indigo-500"
                            >
                              {post.metadata.title}
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
