export interface Job {
    slug: string;
    id: string;
    epoch: number;
    date: string;
    company: string;
    company_logo: string;
    position: string;
    tags: string[];
    logo: string;
    description: string;
    location: string;
    salary_min: number;
    salary_max: number;
    apply_url: string;
}

export interface AdzunaJob {
    company: {
        __CLASS__: string;
        display_name: string;
    };
    salary_max: number;
    location: {
        display_name: string;
        __CLASS__: string;
        area: string[];
    };
    adref: string;
    created: string;
    latitude: number;
    description: string;
    salary_min: number;
    redirect_url: string;
    __CLASS__: string;
    category: {
        __CLASS__: string;
        label: string;
        tag: string;
    };
    longitude: number;
    salary_is_predicted: string;
    title: string;
    id: string;
    contract_time?: string; // Optional field
}

export const GetRemoteAPIJobs = async(): Promise<Job[]> => {
    const remoteOkData = localStorage.getItem('remoteok');
    if (remoteOkData != null) {
        const localStorage: Job[] = JSON.parse(remoteOkData);
        return localStorage;
    }
    const url = 'https://remoteok.com/api';
    const data = {
        ref: 'producthunt',
    };

    const params = Object.keys(data)
        .map((key) => { return `${key}=${encodeURIComponent(data[key])}`; })
        .join('&');
    const fullUrl = `${url}?${params}`;

    // add headers
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    // add to request options
    const requestOptions: any = {
        headers,
    };

    const response = await fetch(fullUrl, requestOptions);
    if (!response.ok) {
        throw new Error('Network response was not ok');
      }

    const apiResponse: Job[] = await response.json();

    localStorage.setItem('remoteok', JSON.stringify(apiResponse));
    return apiResponse;
};

export const GetAdzunaRemoteJobs = async(page: number, whatAnd: string): Promise<{ [key: string]: AdzunaJob }> => {
    const headers = new Headers();
    headers.append('Accept', 'application/json');

    const url = `https://api.adzuna.com/v1/api/jobs/us/search/${page}`;
    const data = {
        app_id          : process.env.REACT_APP_ADZUNA_APP_ID,
        app_key         : process.env.REACT_APP_ADZUNA_APP_KEY,
        results_per_page: 100,
        what_and        : whatAnd,
        where           : 'remote',
    };

    const params = Object.keys(data)
    .map((key) => { return `${key}=${encodeURIComponent(data[key])}`; })
    .join('&');
    const fullUrl = `${url}?${params}`;

    const requestOptions: any = {
        method: 'GET',
        headers,
    };
    const response = await fetch(fullUrl, requestOptions);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const apiResponse: any = await response.json();
    const resultsMap: { [key: string]: AdzunaJob } = {};
    apiResponse.results.forEach((result: AdzunaJob) => {
        resultsMap[result.company.display_name] = result;
    });

    return resultsMap;
};
