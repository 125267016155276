import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { ReactGAActions, ReactGACategory, ReactGALabels } from '../../utils/Constants';

const FourZeroFourLayout = () => {
    const history = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.set({ page: history.pathname });
        ReactGA.send({ hitType: 'pageview', page: history.pathname, title: 'Page Not Found - Nomad Zen Life' });
    }, []);

    return (
        <div>
            <div className="flex flex-col items-start justify-start md:justify-center md:items-center md:flex-row md:space-x-6 md:mt-24 h-screen">
                <div className="pt-6 pb-8 space-x-2 md:space-y-5">
                    <h1 className="text-6xl font-extrabold leading-9 tracking-tight text-gray-900 dark:text-gray-100 md:text-8xl md:leading-14 md:border-r-2 md:px-6">
                        404
                    </h1>
                </div>
                <div className="max-w-md">
                    <p className="mb-4 text-xl font-bold leading-normal md:text-2xl">
                        Sorry we couldn't find this page.
                    </p>
                    <p className="mb-8">But dont worry, you can find plenty of other things on my homepage.</p>
                    <a
                        href="/"
                        className="hover:no-underline"
                        onClick={() => {
                            ReactGA.event({
                                category: ReactGACategory.Button,
                                action  : ReactGAActions.Click,
                                label   : ReactGALabels.HomePageLink,
                            });
                        }}
                    >
                        <button
                            type="button"
                            className="inline px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg shadow focus:outline-none focus:shadow-outline-blue hover:bg-blue-700 dark:hover:bg-blue-500"
                        >
                            Back to homepage
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default FourZeroFourLayout;
