import React, { useState } from 'react';
import { CalendarDaysIcon, HandRaisedIcon } from '@heroicons/react/24/outline';
import { saveAs } from 'file-saver';
import ReactGA from 'react-ga4';
import {
  ReactGAActions,
  ReactGACategory,
  ReactGALabels,
} from '../../utils/Constants';

interface SignUpProps {
  forReport?: boolean;
}

export default function TailwindNewsLetterSignUp(props: SignUpProps) {
  const tmp = props;
  const [name, setName] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');

  const handleDownload = () => {
    // send submit event to GA4
    ReactGA.event({
      category: ReactGACategory.Form,
      action  : ReactGAActions.Submit,
      label   : ReactGALabels.StateOfRemoteWork2024Link,
    });

    const pdfUrl = '/nomadzenlife_report_2024.pdf';
    fetch(pdfUrl)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        saveAs(blob, 'nomadzenlife_report_2024.pdf');
      })
      .catch((error) => {
        return console.error('Error downloading the PDF:', error, tmp);
      });
  };

  return (
    <div className="relative isolate overflow-hidden bg-gray-900 py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
          <div className="max-w-xl lg:max-w-lg">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Curious About Remote Work and Digital Nomading?
            </h2>
            <p className="mt-4 text-lg leading-8 text-gray-300">
              Get Our Free 2024 State of Remote Work and Nomading Resource to
              learn about the latest trends, statistics, and insights.
            </p>
            <div className="mt-6 flex max-w-md gap-x-4 mt-2 leading-8 text-black-300">
              <div className="sr-only mt-2 leading-8 text-black-300">
                Where should we send it?
              </div>
              <div id="mc_embed_shell">
                <div id="mc_embed_signup">
                  <form
                    action="https://nomadzelife.us14.list-manage.com/subscribe/post?u=8dd807f61848dd62a51aa7614&amp;id=9a9ea56f2f&amp;f_id=009953e1f0"
                    method="post"
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    className="validate"
                    style={{ paddingLeft: '0px', marginLeft: '0px' }}
                    onSubmit={() => {
                      return handleDownload();
                    }}
                    target="_blank"
                  >
                    <div id="mc_embed_signup_scroll">
                      <span className="mt-4 text-lg leading-8 text-white">
                        Where should we send it?
                      </span>
                      <div className="mc-field-group w-1">
                      <input
                      type="text"
                      placeholder="First Name"
                      onChange={(e) => {
                        return setName(e.target.value);
                      }}
name="FNAME"
className=" text"
id="mce-FNAME"
value={name}
                      />

                      </div>
                      <div className="mc-field-group w-1">
                        <input
                          type="email"
                          name="EMAIL"
                          placeholder="Email Address"
                          className="required email w-1 bg-white"
                          style={{ width: '300px' }}
                          id="mce-EMAIL"
                          onChange={(e) => {
                            return setUserEmail(e.target.value);
                          }}
                          onSubmit={() => {
                            return handleDownload();
                          }}
                          required
                          value={userEmail}
                        />
                      </div>
                      <div id="mce-responses" className="clear foot">
                        <div
                          className="response"
                          id="mce-error-response"
                          style={{ display: 'none' }}
                        />
                        <div
                          className="response"
                          id="mce-success-response"
                          style={{ display: 'none' }}
                        />
                      </div>
                      <div
                        aria-hidden="true"
                        style={{ position: 'absolute', left: '-5000px' }}
                      >
                        <input
                          type="text"
                          name="b_8dd807f61848dd62a51aa7614_9a9ea56f2f"
                          tabIndex={-1}
                          onSubmit={() => {
                            return handleDownload();
                          }}
                        />
                      </div>
                      <div className="optionalParent">
                        <div className="clear foot pt-3">
                          <input
                            type="submit"
                            value="Get Free Resource!"
                            title="Get Free Resource!"
                            id="mc-embedded-subscribe"
                            className="button rounded-md h-8 w-auto rounded-md text-white font-semibold no-underline bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-80"
                            style={{ margin: 0, fontWeight: 'bold' }}
                            onSubmit={handleDownload}
                          />
                          <p style={{ margin: '0px auto' }} />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {!props.forReport && (
              <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
                <div className="flex flex-col items-start">
                  <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                    <CalendarDaysIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <dt className="mt-4 font-semibold text-white">
                    Weekly articles
                  </dt>
                  <dd className="mt-2 leading-7 text-gray-400">
                    We write articles weekly to keep our audience informed and
                    engaged with the latest digital nomad tips, travel guides,
                    and remote work opportunities.
                  </dd>
                </div>
                <div className="flex flex-col items-start">
                  <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                    <HandRaisedIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <dt className="mt-4 font-semibold text-white">No spam</dt>
                  <dd className="mt-2 leading-7 text-gray-400">
                    We are committed to delivering only valuable content that
                    enriches your digital nomad experience without bombarding
                    you with irrelevant information.
                  </dd>
                </div>
              </dl>
            )}
          </div>
        </div>
        <div
          className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6"
          aria-hidden="true"
        >
          <div
            className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
    </div>
  );
}
