import React from 'react';
import {
 CurrencyDollarIcon, GlobeAsiaAustraliaIcon, SparklesIcon, FlagIcon,
} from '@heroicons/react/20/solid';

const features = [
  {
    name: 'See the world.',
    description:
      'Traveling the world as a digital nomad exposes you to diverse cultures, languages, and traditions. This exposure fosters personal growth, broadens your perspective, and enhances your cultural intelligence. It\'s an opportunity to learn, adapt, and thrive in different environments.',
    icon: FlagIcon,
  },
  {
    name       : 'Increase your purchasing power.',
    description: 'Living in countries with a lower cost of living can significantly reduce your expenses. Many digital nomads choose destinations where they can enjoy a high quality of life at a fraction of the cost compared to their home country. This financial freedom allows you to save money and invest in experiences that enrich your life.',
    icon       : CurrencyDollarIcon,
  },
  {
    name       : 'Build a Global Network.',
    description: 'Being a digital nomad means meeting like-minded individuals from around the world. You\'ll have the chance to build a global network of friends, collaborators, and clients. These connections can open doors to new opportunities, partnerships, and lifelong friendships.',
    icon       : GlobeAsiaAustraliaIcon,
  },
  {
    name       : 'Freedom and flexibiltiy.',
    description: 'As a digital nomad, you have the freedom to choose your work environment. Whether it\'s a cozy café in Paris, a beachside hut in Bali, or a mountain cabin in the Rockies, your office can be anywhere you desire. This flexibility allows you to design your work-life balance, leading to a more fulfilling and stress-free lifestyle.',
    icon       : SparklesIcon,
  },
];

export default function TailwindFeatureSection() {
  return (
    <>
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">
                Live in the future, now.
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">A Life of Zen</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
              Experience the freedom to work from anywhere, allowing you to travel the world while maintaining a sense of peace and balance in your life.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => {
 return (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                );
})}
              </dl>
            </div>
          </div>
          <img
            src="https://images.unsplash.com/photo-1557409518-691ebcd96038?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="Product screenshot"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
     <div className="bg-white py-24 sm:py-32">
     <div className="mx-auto max-w-7xl px-6 lg:px-8">
       <div className="mx-auto max-w-2xl lg:text-center">
         <h2 className="text-base font-semibold leading-7 text-indigo-600">Dive in today</h2>
         <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
         Expert tips into your mailbox each week
         </p>
         <p className="mt-6 text-lg leading-8 text-gray-600">
         Read inspiring stories from fellow digital nomads who have successfully navigated this lifestyle. Our interviews with experienced nomads provide valuable insights, lessons learned, and motivational tips to help you overcome challenges and stay focused on your goals.
         </p>
       </div>
       <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
         <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
           {features.map((feature) => {
 return (
             <div key={feature.name} className="relative pl-16">
               <dt className="text-base font-semibold leading-7 text-gray-900">
                 <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                   <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                 </div>
                 {feature.name}
               </dt>
               <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
             </div>
           );
})}
         </dl>
       </div>
     </div>
     </div>
    </>
  );
}
