import * as React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Home from '../containers/Home';
import About from '../containers/About';
import Map from '../containers/Map';
import FourZeroFour from '../containers/404';
import JobBoard from '../containers/JobBoard';
import FAQ from '../containers/FAQ';
import BlogContainer from '../containers/Blog';
import { getAllPosts, Post } from '../components/blog-post/BlogPostFuncs';
import BlogPage from '../containers/BlogPost';
import TripPlanner from '../containers/TripPlanner';
import Tags from '../containers/Tags';
import SubscribeForReport from '../containers/SubscribeForReport';
import { globalUIContext, UIContext } from '../context/UIContext';
import GPT from '../containers/GPT';

export const DEFAULT_ROUTE_PATHS: { [name: string]: string } = {
  // Informational
  HOME     : '/',
  ABOUT    : '/about',
  FAQ      : '/faq',
  REPORT   : '/state-of-remote-work-2024',
  AIPLANNER: '/ai-travel-guide',

  // Blog-Related
  BLOG       : '/blog',
  BLOGPOST   : '/blog/:slug',
  TAGS       : '/tags',
  TAG        : '/tags/:slug',
  // Comms
  CONTACT    : '/contact',
  // Tools
  TOOLS      : '/tools',
  TRIPPLANNER: '/tools/trip-planner',
  TRAVELMAP  : '/tools/interactive-travel-map',
  JOBBOARD   : '/tools/job-board',
};

export const RouteDeclarations: React.FC = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const history = useLocation();
  const uiContext: UIContext = React.useContext(globalUIContext);

  useEffect(() => {
    const postResponse = getAllPosts(uiContext.state.tag);
    setPosts(postResponse);
  }, [uiContext.state.tag]);

  return (
    <Routes>
      {/* Informtional */}
      <Route path={DEFAULT_ROUTE_PATHS.HOME} Component={() => { return Home(posts); }} />
      <Route path={DEFAULT_ROUTE_PATHS.ABOUT} Component={About} />
      <Route path={DEFAULT_ROUTE_PATHS.TAGS} Component={Tags} />
      <Route path={DEFAULT_ROUTE_PATHS.AIPLANNER} Component={GPT} />
      <Route
path={DEFAULT_ROUTE_PATHS.TAG}
Component={() => {
        const tagValRaw: string = history.pathname.split('/').pop() || '';
        const tagVal: string = tagValRaw.replace(/-/g, ' ');
        uiContext.actions.setTag(tagVal);
        return BlogContainer(posts);
}}
      />
      <Route path={DEFAULT_ROUTE_PATHS.FAQ} Component={FAQ} />
      <Route path={DEFAULT_ROUTE_PATHS.REPORT} Component={SubscribeForReport} />
      {/* Blogs */}
      <Route path={DEFAULT_ROUTE_PATHS.BLOG} Component={() => { return BlogContainer(posts); }} />
      {posts.map((post, index) => {
      return (
          <Route
          key={`${index.toFixed()}`}
path={`/blog/${post.slug}`}
Component={() => { return BlogPage(post); }}
          />
        );
      })}
      {/* Tools */}
      <Route path={DEFAULT_ROUTE_PATHS.TRIPPLANNER} Component={TripPlanner} />
      <Route path={DEFAULT_ROUTE_PATHS.TRAVELMAP} Component={Map} />
      <Route path={DEFAULT_ROUTE_PATHS.JOBBOARD} Component={JobBoard} />
      {/* Not Found */}
      <Route path="*" Component={FourZeroFour} />
    </Routes>
  );
};
