import matter from 'gray-matter';
import { Buffer } from 'buffer';

// Polyfill Buffer if needed
if (typeof window !== 'undefined' && !window.Buffer) {
  window.Buffer = Buffer;
}

export interface Author {
  name: string;
  bio: string;
  profileImage: string;
}

export interface Metadata {
  author: Author;
  createdOn: string;
  description: string;
  headerImageName: string;
  imagePreviewName: string;
  metaDescription: string;
  metaTitle: string;
  readMoreDisabled: boolean;
  slug: string;
  tags: string[];
  title: string;
  type: string;
  unsplashPhoto: boolean;
}

export interface Post {
  content: string;
  metadata: Metadata;
  slug: string;
}

// Using require.context to dynamically import all markdown files as raw text
export const importAll = (r): Post[] => {
  const keys = r.keys();
  return keys.map((fileName: string) => {
    const fileModule = r(fileName);
    const fileContent = typeof fileModule === 'string' ? fileModule : fileModule.default; // Ensure this is treated as a string
    const { data, content } = matter(fileContent);
    return {
      slug    : fileName.replace('./', '').replace('.md', ''),
      metadata: data,
      content,
    };
  });
};
export const getAllPosts = (tag?: string | undefined): Post[] => {
  const context = require.context(
    '!!raw-loader!../../data/blog-posts',
    true,
    /\.md$/,
  );
  let imports = importAll(context);
  // sort imports by creation date
  imports.sort((a: Post, b: Post) => {
    return (
      new Date(b.metadata.createdOn).getTime()
      - new Date(a.metadata.createdOn).getTime()
    );
  });

  if (tag !== undefined) {
    imports = imports.filter((a: Post) => {
      return a.metadata.tags.includes(tag);
    });
  }

  return imports;
};

export const getPostData = async (
  slug: string | undefined,
): Promise<{ content: string; metadata: Record<string, any> }> => {
  const context = require.context(
    '!!raw-loader!../../data/blog-posts',
    false,
    /\.md$/,
  );
  const fileContent = context(`./${slug}.md`) as string; // Ensure this is treated as a string
  const { content, data } = matter(fileContent);
  return { content, metadata: data };
};
