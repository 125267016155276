import React, { useEffect, useState } from 'react';
import ReactMapGL, { Marker, Popup } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const MAPBOX_TOKEN = process.env.REACT_APP_NZL_MAPBOX_ACCESS_KEY;

interface Location {
  id: number;
  name: string;
  latitude: number;
  longitude: number;
  blogUrl: string;
  description: string;
  imageUrl: string; // Add image URL for Unsplash image
}

const initialLocations: Location[] = [
  {
    id       : 16,
    name     : 'Utrecht',
    latitude : 52.0907,
    longitude: 5.1214,
    blogUrl  : '#',
    description:
      "Utrecht is a city in the Netherlands known for its historic canals and architecture. It is the world's most bike-friendly city.",
    imageUrl: 'https://images.unsplash.com/photo-1667053312990-c17655704190?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', // Specific Unsplash image link for Utrecht
  },
  {
    id       : 17,
    name     : 'Cologne',
    latitude : 50.9375,
    longitude: 6.9603,
    blogUrl  : '#',
    description:
      'Cologne is a city in Germany known for its cathedral and cultural sites and museums.',
    imageUrl: 'https://images.unsplash.com/photo-1504801019156-beabdea673be?q=80&w=2552&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', // Specific Unsplash image link for Cologne
  },
  {
    id       : 18,
    name     : 'Amsterdam',
    latitude : 52.3676,
    longitude: 4.9041,
    blogUrl  : '#',
    description:
      'Amsterdam is the capital of the Netherlands famous for its artistic heritage and elaborate canal system. It is also known for its cycling culture.',
    imageUrl: 'https://images.unsplash.com/photo-1529943247435-a5974e63d6e4?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', // Specific Unsplash image link for Amsterdam
  },
  {
    id       : 19,
    name     : 'Antwerp',
    latitude : 51.2194,
    longitude: 4.4025,
    blogUrl  : '#',
    description:
      'Antwerp is a city in Belgium known for its fashion and diamond districts. It is also famous for its art and architecture.',
    imageUrl: 'https://plus.unsplash.com/premium_photo-1661964151110-79a96d913e78?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', // Specific Unsplash image link for Antwerp
  },
  {
    id         : 1,
    name       : 'Tokyo',
    latitude   : 35.6764,
    longitude  : 139.65,
    blogUrl    : '#',
    description: 'Tokyo is the capital of Japan known for its vibrant pop culture and historic temples.',
    imageUrl:
      'https://images.unsplash.com/photo-1542051841857-5f90071e7989?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', // Specific Unsplash image link for Tokyo
  },
  {
    id       : 2,
    name     : 'Kyoto',
    latitude : 35.0116,
    longitude: 135.7681,
    blogUrl  : '#',
    description:
      'Kyoto is a city in Japan known for its classical Buddhist temples.',
    imageUrl:
      'https://images.unsplash.com/photo-1624253321171-1be53e12f5f4?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    id       : 3,
    name     : 'Osaka',
    latitude : 34.6937,
    longitude: 135.5023,
    blogUrl  : '#',
    description:
      'Osaka is a major city in Japan known for its modern architecture and nightlife.',
    imageUrl: 'https://images.unsplash.com/photo-1702240396435-3e5e892568f5?q=80&w=2554&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', // Specific Unsplash image link for Osaka
  },
  {
    id         : 4,
    name       : 'Kailua',
    latitude   : 21.4022,
    longitude  : -157.7394,
    blogUrl    : '#',
    description: 'Kailua is a town on the east coast of Oahu, Hawaii',
    imageUrl   : 'https://images.unsplash.com/photo-1710604686551-a82d7032672a?q=80&w=2673&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', // Specific Unsplash image link for Kailua
  },
  {
    id       : 5,
    name     : 'San Francisco',
    latitude : 37.7749,
    longitude: -122.4194,
    blogUrl  : '#',
    description:
      'San Francisco is a city in California known for the Golden Gate Bridge',
    imageUrl: 'https://images.unsplash.com/photo-1547190994-0dfe4ab1bdae?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', // Specific Unsplash image link for San Francisco
  },
  {
    id       : 6,
    name     : 'Hongdae',
    latitude : 37.5547,
    longitude: 126.9194,
    blogUrl  : '#',
    description:
      'Hongdae is a neighborhood in Seoul, South Korea famous for its youthful ambience',
    imageUrl: 'https://images.unsplash.com/photo-1574442274210-ba4925b7ae61?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', // Specific Unsplash image link for Hongdae
  },
  {
    id       : 7,
    name     : 'Quebec',
    latitude : 46.8139,
    longitude: -71.208,
    blogUrl  : '#',
    description:
      'Quebec City is the capital of the Canadian province of Quebec',
    imageUrl: 'https://images.unsplash.com/photo-1710881710078-d25d578fedc3?q=80&w=2664&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', // Specific Unsplash image link for Quebec
  },
  {
    id       : 8,
    name     : 'Denver',
    latitude : 39.7392,
    longitude: -104.9903,
    blogUrl  : '#',
    description:
      'Denver is the capital of Colorado known for its outdoor activities',
    imageUrl: 'https://images.unsplash.com/photo-1617246405400-462cb1ab98ab?q=80&w=2598&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', // Specific Unsplash image link for Denver
  },
  {
    id       : 9,
    name     : 'Boston',
    latitude : 42.3601,
    longitude: -71.0589,
    blogUrl  : '#',
    description:
      'Boston is the capital of Massachusetts famous for its history',
    imageUrl: 'https://images.unsplash.com/photo-1573524949339-b830334a31ee?q=80&w=2531&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', // Specific Unsplash image link for Boston
  },
  {
    id         : 10,
    name       : 'Seward',
    latitude   : 60.1042,
    longitude  : -149.4422,
    blogUrl    : '#',
    description: 'Seward is a city in Alaska known for its scenic views',
    imageUrl   : 'https://images.unsplash.com/photo-1636569999489-695f8fc249bc?q=80&w=2662&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', // Specific Unsplash image link for Seward
  },
  {
    id         : 11,
    name       : 'Hamburg',
    latitude   : 53.5511,
    longitude  : 9.9937,
    blogUrl    : '#',
    description: 'Hamburg is a major port city in Germany',
    imageUrl   : 'https://images.unsplash.com/photo-1684155442696-15fe3a7594a2?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', // Specific Unsplash image link for Hamburg
  },
  {
    id       : 12,
    name     : 'Central Hong Kong',
    latitude : 22.2795,
    longitude: 114.1628,
    blogUrl  : '#',
    description:
      'Central Hong Kong is the central business district of Hong Kong',
    imageUrl: 'https://plus.unsplash.com/premium_photo-1661886716300-6c0a7b438d2d?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', // Specific Unsplash image link for Central Hong Kong
  },
  {
    id       : 13,
    name     : 'Cabo San Lucas',
    latitude : 22.8905,
    longitude: -109.9167,
    blogUrl  : '#',
    description:
      'Cabo San Lucas is a resort city in Mexico known for its beaches and nightlife',
    imageUrl: 'https://images.unsplash.com/photo-1527734055665-8def83921139?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', // Specific Unsplash image link for Cabo San Lucas
  },
  {
    id       : 14,
    name     : 'New York City',
    latitude : 40.7128,
    longitude: -74.006,
    blogUrl  : '#',
    description:
      'New York City is a major city in the United States known for its diversity',
    imageUrl:
      'https://images.unsplash.com/photo-1496588152823-86ff7695e68f?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    id       : 15,
    name     : 'Stamford',
    latitude : 41.0534,
    longitude: -73.5387,
    blogUrl  : '#',
    description:
      'Stamford is a city in Connecticut known for its financial industry',
    imageUrl: 'https://images.unsplash.com/photo-1628531951560-e4fd0133f419?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', // Specific Unsplash image link for Stamford
  },
];

const MapComponent: React.FC = () => {
  const [locations] = useState<Location[]>(initialLocations);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null,
  );
  const [mapLib, setMapLib] = useState<any>(null);

  useEffect(() => {
    const loadMapLib = async () => {
      const mapboxgl = await import('mapbox-gl');
      setMapLib(mapboxgl);
    };
    loadMapLib();
  }, []);


  const handleMarkerClick = (location: Location) => {
    setSelectedLocation(location);
  };

  const handleClosePopup = () => {
    setSelectedLocation(null);
  };

  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Interactive Map
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600 pb-4">
            Welcome to NomadZenLife's Interactive Map, an interactive map showcasing the places that I have traveled to around the world.
            Click on the markers to learn more about each location and see links to my experience and recommendations.
          </p>
          <div className="h-screen flex justify-center items-center">
            <ReactMapGL
              mapboxAccessToken={MAPBOX_TOKEN}
              mapLib={mapLib}
              initialViewState={{
                longitude: -150,
                latitude : 20,
                zoom     : 2,
              }}
              style={{
                marginTop   : '10px',
                paddingTop  : '10px',
                width       : '90vw',
                height      : '800px',
                borderRadius: '10px',
                boxShadow   : '0px 4px 10px rgba(0, 0, 0, 0.4)',
              }}
              mapStyle="mapbox://styles/mapbox/streets-v11"
            >
              {locations.map((location) => {
                return (
                  <Marker
                    key={location.id}
                    latitude={location.latitude}
                    longitude={location.longitude}
                  >
                    <button
                      type="button"
                      className="marker-btn"
                      onClick={() => {
                        return handleMarkerClick(location);
                      }}
                    >
                      <img
                        className="marker-icon"
                        style={{ width: '30px', height: '30px' }}
                        src="/marker-editor.svg"
                        alt="Marker Icon"
                      />
                    </button>
                  </Marker>
                );
              })}

              {selectedLocation && (
                <Popup
                  latitude={selectedLocation.latitude}
                  longitude={selectedLocation.longitude}
                  onClose={handleClosePopup}
                  closeButton
                  closeOnClick={false}
                  className=""
                  style={{
                    borderRadius: '10px',
                    boxShadow   : '0px 4px 10px',
                  }}
                >
                  <div
                    className="p-4"
                    style={{ backgroundColor: 'transparent' }}
                  >
                    <h2 className="text-xl font-bold mb-2">
                      {selectedLocation.name}
                    </h2>
                    <img
                      src={selectedLocation.imageUrl}
                      alt={selectedLocation.name}
                      className="mb-2 rounded-lg"
                      style={{ width: '100%', height: '50%' }}
                    />
                    <p className="text-gray-700 mb-2">
                      {selectedLocation.description}
                    </p>
                    <a
                      href={selectedLocation.blogUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      More about {selectedLocation.name}
                    </a>
                  </div>
                </Popup>
              )}
            </ReactMapGL>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapComponent;
