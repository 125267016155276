import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';

const QuestionMarkHTML = (): React.ReactElement => {
  return (
    <div className="relative pr-2">
      <dt className="potext-base font-semibold leading-7 text-gray-900">
        <div className="left-0 top-0 flex h-5 w-5 items-center justify-center rounded-lg bg-indigo-600">
          <QuestionMarkCircleIcon
            className="h-5 w-5 text-white"
            aria-hidden="true"
          />
        </div>
      </dt>
    </div>
  );
};

export default function TailwindFAQ() {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="lg:pr-8 lg:pt-4">
          <div className="lg:max-w-xlg">
            <section className="bg-white dark:bg-gray-900">
              <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                  FAQ (Frequently asked questions)
                </h2>
                <div className="grid pt-8 text-left border-t border-gray-200 md:gap-16 dark:border-gray-700 md:grid-cols-2">
                  <div>
                    <div className="mb-10">
                      <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                        {QuestionMarkHTML()}
                        1. What is a digital nomad?
                      </h3>
                      <p className="text-gray-500 dark:text-gray-400">
                        A digital nomad is someone who leverages technology to
                        work remotely while traveling and living in various
                        locations around the world. They typically use laptops,
                        smartphones, and internet connectivity to perform their
                        jobs, allowing them to explore new places without being
                        tied to a single location.
                      </p>
                    </div>
                    <div className="mb-10">
                      <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                        {QuestionMarkHTML()}
                        2. What are the benefits of becoming a digital nomad?
                      </h3>
                      <p className="text-gray-500 dark:text-gray-400">
                        Becoming a digital nomad offers numerous benefits,
                        including the freedom to travel, the opportunity to
                        experience different cultures, a flexible work schedule,
                        reduced living costs (depending on location), and an
                        improved work-life balance. It also encourages personal
                        growth and adaptability.
                      </p>
                    </div>
                    <div className="mb-10">
                      <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                        {QuestionMarkHTML()}
                        3. How can your blog help me decide if the digital nomad
                        lifestyle is right for me?
                      </h3>
                      <p className="text-gray-500 dark:text-gray-400">
                        Our blog provides comprehensive insights, real-life
                        experiences, and practical advice from seasoned digital
                        nomads. We cover topics such as the pros and cons of the
                        lifestyle, how to prepare for the transition, and what
                        to expect on the road. This information can help you
                        make an informed decision about whether this lifestyle
                        suits your personal and professional goals.
                      </p>
                    </div>
                    <div className="mb-10">
                      <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                        {QuestionMarkHTML()}
                        4. What resources do you offer for aspiring digital
                        nomads?
                      </h3>
                      <p className="text-gray-500 dark:text-gray-400">
                        We offer a wide range of resources, including guides on
                        finding remote work, tips for staying productive while
                        traveling, budgeting advice, and recommendations for
                        essential tools and gear. We also provide
                        location-specific advice, such as the best cities for
                        digital nomads, visa requirements, and cost of living
                        comparisons.
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="mb-10">
                      <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                        {QuestionMarkHTML()}
                        5. How do I find remote work opportunities?
                      </h3>
                      <p className="text-gray-500 dark:text-gray-400">
                        Our blog features articles on various remote job boards,
                        freelance platforms, and networking strategies to help
                        you find remote work opportunities. We also provide tips
                        on crafting a compelling remote work resume and how to
                        successfully apply for remote positions.
                      </p>
                    </div>
                    <div className="mb-10">
                      <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                        {QuestionMarkHTML()}
                        6. Can you recommend specific locations for digital
                        nomads?
                      </h3>
                      <p className="text-gray-500 dark:text-gray-400">
                        Yes, we regularly publish detailed guides on popular
                        digital nomad destinations, covering aspects such as
                        internet connectivity, coworking spaces, cost of living,
                        local culture, safety, and lifestyle. Our
                        recommendations are based on firsthand experiences and
                        feedback from our community of digital nomads.
                      </p>
                    </div>
                    <div className="mb-10">
                      <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                        {QuestionMarkHTML()}
                        7. How do I stay productive while traveling?
                      </h3>
                      <p className="text-gray-500 dark:text-gray-400">
                        Staying productive while traveling can be challenging,
                        but we offer several strategies to help you maintain
                        focus and efficiency. This includes creating a
                        structured daily routine, setting up a dedicated
                        workspace, using productivity tools and apps, and
                        balancing work with leisure activities.
                      </p>
                    </div>
                    <div className="mb-10">
                      <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                        {QuestionMarkHTML()}
                        10. How do I build a community while living a nomadic
                        lifestyle?
                      </h3>
                      <p className="text-gray-500 dark:text-gray-400">
                        Building a community is essential for maintaining social
                        connections and support. Our blog offers tips on how to
                        connect with other digital nomads through coworking
                        spaces, online forums, social media groups, and local
                        events. We also highlight popular digital nomad hubs
                        where you can easily meet like-minded individuals.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
