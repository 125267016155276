import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import TailwindHeader from '../components/header/TailwindHeader';
import { Footer, HelmetComponent, PageTitle } from '../components';
import { siteMetadata } from '../data/siteMetadata';
import { DEFAULT_ROUTE_PATHS } from '../routes/RouteDeclarations';
import HotelLook from '../components/affiliate-widgets/Hotellook';
import Trips from '../components/affiliate-widgets/Trips';
import BudgetCalculator from '../components/budget-calculator/BudgetCalculator';

export default function TripPlanner() {

  const history = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
      ReactGA.set({ page: history.pathname });
      ReactGA.send({ hitType: 'pageview', page: history.pathname, title: 'Trip Planner - Nomad Zen Life' });
  }, []);

  return (
    <>
    <HelmetComponent
      title="Trip Planner - Nomad Zen Life"
      description="Plan your next trip with the Nomad Zen Life Trip Planner tool. Find the best flights, accommodations, and activities for your next adventure."
      URL={`${siteMetadata.siteUrl}${DEFAULT_ROUTE_PATHS.TRIPPLANNER}`}
    />
    <TailwindHeader />
    <div className="overflow-hidden bg-white pt-24">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl  gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-1">
            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-20xl">
                <p className="mt-2 text-3xl max-w-lg font-bold tracking-tight text-gray-900 sm:text-4xl">
                  <PageTitle className="">NZL Digital Nomad Planner</PageTitle>
                </p>
                <p
                  className="mt-6 text-xl leading-8 text-gray-600"
                  style={{ textAlign: 'justify' }}
                />
                <p
                className="prose text-xl justify-content: normal max-w-full"
                >
                  Plan for your digital nomad life right here. Find the best flights, accommodations, and activities for your next adventure. Create custom itineraries, book flights, and explore popular destinations.
                </p>
                <h2 className="text-2xl py-4 font-semibold leading-7 text-indigo-600">
                  1. Define Your Budget
                </h2>
                <p
                className="prose text-xl justify-content: normal max-w-full"
                >
                Before booking your flights and accommodations, it's essential to define your budget. By setting a budget, you can ensure that you're getting the best value for your money and avoid overspending. Our budget calculator tool makes it easy to plan your trip and stay within your budget. Simply enter your travel dates, destination, and preferences, and the tool will generate a custom budget for your trip.
                </p>
                <BudgetCalculator />
                <h2 className="text-2xl py-4 font-semibold leading-7 text-indigo-600">
                  2. Choose Your Destination
                </h2>
                <p
                className="prose text-xl justify-content: normal max-w-full"
                >
                The next step to planning your next adventure is to choose your destination. Whether you're looking for a relaxing beach vacation, a cultural city break, or an outdoor adventure, we've got you covered. Our trip planner tool makes it easy to find the best flights, accommodations, and activities for your next trip.
                </p>
              </div>
              <Trips />
              <h2 className="text-2xl font-semibold leading-7 text-indigo-600">
                  3. Choose Your Accommodations
              </h2>
                <p
                className="prose text-xl justify-content: normal max-w-full"
                >
                After choosing your destination, you'll want to book your accommodations. Whether you're looking for a luxury hotel, a budget-friendly hostel, or a cozy vacation rental, the acommodations search tool will help you find the perfect place to stay. With thousands of options to choose from, you're sure to find the perfect accommodations for your next trip.
                </p>
                <HotelLook />
                <p
                className="py-10 prose py-5 text-xl justify-content: normal max-w-full"
                >
                {'In addition to this tool, I highly recommend checking out the short term rental marketplace called '}
                <a href="https://www.furnishedfinder.com/" target="_blank" rel="noreferrer">Furnished Finder</a>. Furnished Finder is the #1 site for furnished housing. They specialize in providing furnished housing for digital nomads, travel nurses and other professionals looking for short-term stays. They have thousands of listings across the US and Canada, and they offer a wide range of housing options to suit your needs. Whether you're looking for a short-term rental, a long-term lease, or something in between, Furnished Finder has you covered.
                </p>
                <h2 className="text-2xl font-semibold leading-7 text-indigo-600">
                 4. Plan Your Activities
                </h2>
                <p
                className="prose text-xl justify-content: normal max-w-full"
                >
                After booking your accommodations, you'll want to plan your activities. Whether you're looking for a relaxing hiking trail, a beautiful beach, or a unique attraction, you'll want to develop a list of activities to enjoy working from this remote location. While we are currently working on developing an activity search tool, we recommend checking out the following resources:
                </p>
                <ul className="list-disc pl-6 py-2 prose text-xl justify-content: normal max-w-full">
                  <li className="py-2">
                    <a className="font-bold" href="https://www.wheredoyougo.com/" target="_blank" rel="noreferrer">Where Do You Go?</a> - A free, easy-to-use app that helps you plan your next adventure by providing real-time travel information, attraction recommendations, and travel tips.
                  </li>
                  <li className="py-2 prose text-xl justify-content: normal max-w-full">
                    <a className="font-bold" href="https://www.tripadvisor.com/" target="_blank" rel="noreferrer">TripAdvisor</a> - A popular travel destination and attraction review site that offers a wide range of activities, dining options, and travel tips.
                  </li>
                  <li className="py-2 prose text-xl justify-content: normal max-w-full">
                    <a className="font-bold" href="https://www.citysearch.com/" target="_blank" rel="noreferrer">CitySearch</a> - A travel destination search tool that helps you find the perfect place to stay, eat, and visit based on your preferences and budget.
                  </li>
                </ul>
                <h2 className="text-2xl py-4 font-semibold leading-7 text-indigo-600">
                  5. Live The Nomad Zen Life
                </h2>
                <p
                className="prose text-xl justify-content: normal max-w-full"
                >
                  As a nomad, living in a remote location can be challenging. However, with the help of our trip planner tool and the resources we've mentioned, you'll be able to enjoy your adventures and work from this remote location. Stay connected to the world around you by joining our social media channels, attending local events, and following travel bloggers. And remember, the key to living the Nomad Zen Life is to keep learning and growing, and to always prioritize your personal well-being and your relationships.
                </p>
            </div>
          </div>
        </div>
    </div>
    <Footer />
    </>
  );
}
