import React, { useEffect, useRef } from 'react';

export default function HotelLook() {
  const scriptContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scriptSrc = 'https://tp.media/content?currency=usd&trs=331827&shmarker=556388&show_hotels=true&powered_by=true&locale=en_us&searchUrl=search.hotellook.com&primary_override=%23FF8E01&color_button=%23FF8E01&color_icons=%23FF8E01&secondary=%23FFFFFF&dark=%23262626&light=%23FFFFFF&special=%23C4C4C4&color_focused=%23FF8E01&border_radius=5&no_labels=true&plain=true&promo_id=7873&campaign_id=101';

    if (scriptContainerRef.current && !scriptContainerRef.current.querySelector(`script[src="${scriptSrc}"]`)) {
      const script = document.createElement('script');
      script.src = scriptSrc;
      script.async = true;
      script.charset = 'utf-8';
      scriptContainerRef.current.appendChild(script);
    }

    return () => {
      if (scriptContainerRef.current) {
        const existingScript = scriptContainerRef.current.querySelector(`script[src="${scriptSrc}"]`);
        if (existingScript) {
          scriptContainerRef.current.removeChild(existingScript);
        }
      }
    };
  }, []);

  return (
    <div className="relative overflow-hidden bg-white">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 lg:min-h-[550px]">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-1">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Find the best hotels for your next trip
              </p>
              <h2 className="text-base py-3 font-semibold leading-7 text-indigo-600">
                Book your perfect stay at the perfect location
              </h2>
            </div>
          </div>
        </div>
        <div
        ref={scriptContainerRef}
className="z-900 flex trip-dot-com-anchor"
        />
      </div>
    </div>
  );
}
