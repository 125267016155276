import React from 'react';
import {
 Dialog, DialogBackdrop, DialogPanel, DialogTitle, TransitionChild,
} from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { AdzunaJob } from '../../apis/jobsAPI';

interface JobProps {
  selectedJob: AdzunaJob|null;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
}

const formatter = new Intl.NumberFormat('en-US', {
  style   : 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});


export default function TailwindSlideover(props: JobProps) {

  return (
    <Dialog className="relative z-10" open={props.open} onClose={props.setOpen}>
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto relative w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <TransitionChild>
                <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 duration-500 ease-in-out data-[closed]:opacity-0 sm:-ml-10 sm:pr-4">
                  <button
                    type="button"
                    className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                    onClick={() => { return props.setOpen(false); }}
                  >
                    <span className="absolute -inset-2.5" />
                    <span className="sr-only">Close panel</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              {
                props.selectedJob && (
              <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                <div className="px-4 sm:px-6">
                  <DialogTitle className="text-base text-xl font-bold leading-6 text-gray-900">{props.selectedJob.title}</DialogTitle>
                  <time dateTime={props.selectedJob.created} className="text-gray-500">
                    {`Posted: ${new Date(props.selectedJob.created).toLocaleDateString()}`}
                  </time>
                </div>
                <div className="relative mt-6 flex-1 px-4 sm:px-6">
                    <div key={props.selectedJob.id} className="mt-2">

                      <div className="flex items-center">
                        <div className="flex-shrink-0" />
                        <div className="flex items-center gap-x-4 text-xs" />
                      </div>
                    </div>
                        <div className="ml-3">
                        <p className="text-lg leading-5 font-semibold">{props.selectedJob.company.display_name}</p>
                          <p className="text-sm leading-5 text-gray-900">{props.selectedJob.location.display_name}</p>
                          <p className="text-sm font-semibold leading-5 text-gray-900">{`${formatter.format(props.selectedJob.salary_min)} - ${formatter.format(props.selectedJob.salary_max)}`}</p>
                          <p className="text-md pt-4 leading-5 text-gray-900">{props.selectedJob.description}</p>
                          <div className="pt-2 pb-2" />
                          <a className="text-md pt-4" href={props.selectedJob.redirect_url} target="_blank" rel="noreferrer">Apply Now</a>
                          <div className="pb-3" />
                        </div>
                <span className="relative z-10 rounded-full bg-gray-50 px-3 py-2 text-sm text-gray-600 hover:bg-gray-100">
                  {props.selectedJob.category.tag}
                </span>
                <span className="relative z-10 rounded-full bg-gray-50 px-3 py-2 text-sm text-gray-600 hover:bg-gray-100">
                  {props.selectedJob.contract_time}
                </span>
                </div>
              </div>
              )
}
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
