import React, { useEffect, useState } from 'react';
import { GetAdzunaRemoteJobs, AdzunaJob } from '../../apis/jobsAPI';
import TailwindSlideover from '../slideover/TailwindSlideover';

export default function TailwindJobBoard() {
  const [jobs, setJobs] = useState<{ [key: string]: AdzunaJob }>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedJob, setSelectedJob] = useState<AdzunaJob | null>(null);
  const [open, setOpen] = useState(false);
const [whatAnd, setWhatAnd] = useState<string>('software engineer remote');
//   const [page, setPage] = useState<number>(1);

  const getData = async () => {
    try {
      const jobData = await GetAdzunaRemoteJobs(1, whatAnd);
      setJobs(jobData);
      setLoading(false);
    } catch (error) {
      setError('Error fetching jobs');
      setLoading(false);
    }
  };

  const handleJobSelection = (job: AdzunaJob) => {
    setSelectedJob(job);
    setOpen(true);
  };

  useEffect(() => {
    getData();
  }, []);

  if (error) return <p>{error}</p>;

  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Jobs
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600 pb-4">
            Welcome to NomadZenLife's job board, where we share the latest remote job opportunities. Explore the job listings below.
          </p>
            <div className="pt-2 pb-5 mx-auto">
                <div
                className="text-sm font-medium text-gray-900 sr-only dark:text-white"
                >Enter Keywords
                </div>
                <div className="relative">
                    <input
                    type="search"
                    id="default-search"
                    className="block w-full p-3 pt-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder={whatAnd}
                    onChange={(event) => { return setWhatAnd(event.target.value); }}
                    required
                    />
                    <button
                    type="submit"
                    className="text-white absolute end-2.5 bottom-2 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    onClick={() => { getData(); }}
                    >Search
                    </button>
                </div>
            </div>
        </div>
        {
            loading
                ? (
                    <p>Loading...</p>
                )
                : error
                ? (
                 <p>Sorry, no jobs at this time.</p>
                )
                : Object.keys(jobs).length > 0 ? (
            Object.keys(jobs).map((key) => {
            return (
                <div
                key={jobs[key].id}
                className="flex flex-row justify-between items-center bg-white shadow-md rounded-lg p-3 m-2"
                >
                <div className="flex flex-col items-start justify-center flex-grow">
                  <h3 className="text-xl font-semibold">{jobs[key].company.display_name}</h3>
                  <h3 className="text-lg">{jobs[key].title}</h3>
                <span className="rounded-full bg-gray-50 font-medium text-gray-600 hover:bg-gray-100">
                  {jobs[key].location.display_name}
                </span>
                <a className="text-sm pt-2" href={jobs[key].redirect_url} target="_blank" rel="noreferrer">Apply Now</a>
                </div>
                <button
                  type="button"
                  onClick={() => {
                    return handleJobSelection(jobs[key]);
                  }}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
                >
                  View Job
                </button>
                </div>

            );
          })
          ) : (
            <p>Please expand your search.</p>
          )
}
          <TailwindSlideover
            selectedJob={selectedJob}
            open={open}
            setOpen={setOpen}
          />
        </div>
    </div>
  );
}
