import React, { useEffect, useState } from 'react';
import smoothscroll from 'smoothscroll-polyfill';

const ScrollTopAndComment = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        smoothscroll.polyfill();
        const handleWindowScroll = () => {
            if (window.scrollY > 50) setShow(true);
            else setShow(false);
        };

        window.addEventListener('scroll', handleWindowScroll);
        return () => { return window.removeEventListener('scroll', handleWindowScroll); };
    }, []);

    const handleScrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className="fixed flex-col hidden gap-3 right-8 bottom-8 md:flex">
            <button
                aria-label="Scroll To Top"
                type="button"
                onClick={handleScrollTop}
                style={{ opacity: show ? 1 : 0 }}
                className="p-2 text-gray-500 transition-all bg-gray-200 rounded-full dark:text-gray-400 dark:bg-gray-700 dark:hover:bg-gray-600 hover:bg-gray-300"
            >
                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                    <path
                        fillRule="evenodd"
                        d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                    />
                </svg>
            </button>
        </div>
    );
};

export default ScrollTopAndComment;
