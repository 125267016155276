import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import TailwindHeader from '../components/header/TailwindHeader';
import { Footer, HelmetComponent } from '../components';
import MapComponent from '../components/map/MapLayout';
import { siteMetadata } from '../data/siteMetadata';
import { DEFAULT_ROUTE_PATHS } from '../routes/RouteDeclarations';

export default function Map() {

  const history = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
      ReactGA.set({ page: history.pathname });
      ReactGA.send({ hitType: 'pageview', page: history.pathname, title: 'Interactive Map for Digital Nomads - Nomad Zen Life' });
  }, []);

  return (
    <>
    <HelmetComponent
      title="Interactive Map for Digital Nomads - Nomad Zen Life"
      description="Explore our interactive map to find the best places to live and work as a digital nomad. Discover your next destination today!"
      URL={`${siteMetadata.siteUrl}${DEFAULT_ROUTE_PATHS.TRAVELMAP}`}
    />
    <TailwindHeader />
    <MapComponent />
    <Footer />
    </>
  );
}
