import React from 'react';
import TailwindHeader from '../components/header/TailwindHeader';
import { Footer } from '../components';
import FourZeroFourLayout from '../components/404/404';

export default function FourZeroFour() {
  return (
    <>
      <TailwindHeader />
      <FourZeroFourLayout />
      <Footer />
    </>
  );
}
