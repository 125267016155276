import React, { Suspense, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { siteMetadata } from '../../data/siteMetadata';
import {
 ReactGAActions, ReactGACategory, ReactGALabels, UsefulServices,
} from '../../utils/Constants';

const LazyLoadVideoComponent: React.FC = () => {

    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const handleIntersection = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            Array.from(videoElement.children).forEach((source) => {
              if (source.tagName === 'SOURCE') {
                const videoSource = source as HTMLSourceElement;
                if (videoSource.dataset.src) {
                  videoSource.src = videoSource.dataset.src;
                }
              }
            });
            videoElement.load();
            observer.unobserve(videoElement);
          }
        });
      };

      const observer = new IntersectionObserver(handleIntersection);
      observer.observe(videoElement);

      // eslint-disable-next-line consistent-return
      return () => {
        observer.disconnect();
      };
    }, []);

    return (
          <video
            ref={videoRef}
            autoPlay
            className="absolute top-0 left-0 w-full h-full object-cover overflow-hidden"
            loop
            muted
          >
            <source
              data-src="/nzl.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
      );
    };

const LazyLoadVideoComponentLazy = React.lazy(() => { return Promise.resolve({ default: LazyLoadVideoComponent }); });

export default function TailwindHome () {
    return (
        <div className="bg-white">
            <Suspense fallback={<div>Loading video...</div>}>
            { false && <LazyLoadVideoComponentLazy /> }
            </Suspense>
            <div className="relative isolate px-6 pt-14 lg:px-8">
                <div
                    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
                <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
                    <div className="sm:mb-8 sm:flex sm:justify-center py-3">
                        <div className="relative rounded-full px-3 py-1 text-lg leading-6 text-black ring-1 ring-gray-900/10 hover:ring-gray-900/20 font-bold">
                            Check out our AI travel guide!🤖
                            <a
                            href={UsefulServices.AITravelGuide}
                            className="font-semibold text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => {
                                ReactGA.event({
                                    category: ReactGACategory.Button,
                                    action  : ReactGAActions.Click,
                                    label   : ReactGALabels.AITravelGuideLink,
                                });
                              }}
                            >
                                <span className="absolute inset-0" aria-hidden="true" />
                            </a>
                        </div>
                    </div>
                    <div className="text-center">
                        <h1 className="text-4xl font-bold tracking-tight text-black sm:text-6xl">
                            {siteMetadata.home.title}
                        </h1>
                        <p className="mt-6 text-lg leading-8 text-black font-bold">
                            {siteMetadata.home.description}
                        </p>
                        <div className="mt-10 flex items-center justify-center gap-x-6">
                            <Link
                                to="/state-of-remote-work-2024"
                                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                onClick={() => {
                                    ReactGA.event({
                                        category: ReactGACategory.Button,
                                        action  : ReactGAActions.Click,
                                        label   : ReactGALabels.AboutPageLink,
                                    });
                                  }}
                            >
                                Get started
                            </Link>
                            <Link
                            to="/about"
                            className="rounded bg-indigo-600 py-1.5 px-1.5 text-sm font-semibold leading-6 text-white"
                            onClick={() => {
                                ReactGA.event({
                                    category: ReactGACategory.Button,
                                    action  : ReactGAActions.Click,
                                    label   : ReactGALabels.AboutPageLink,
                                });
                              }}
                            >
                                Learn more <span aria-hidden="true">→</span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div
                    className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
