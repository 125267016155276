import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import TailwindHeader from '../components/header/TailwindHeader';
import { Footer, HelmetComponent } from '../components';
import TailwindJobBoard from '../components/job-board/TailwindJobBoard';
import { siteMetadata } from '../data/siteMetadata';
import { DEFAULT_ROUTE_PATHS } from '../routes/RouteDeclarations';

export default function JobBoard() {

  const history = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
      ReactGA.set({ page: history.pathname });
      ReactGA.send({ hitType: 'pageview', page: history.pathname, title: 'Digital Nomad Job Board - Nomad Zen Life' });
  }, []);

  return (
    <>
    <HelmetComponent
      title="Digital Nomad Job Board - Nomad Zen Life"
      description="Find remote jobs that allow you to work from anywhere in the world. Explore opportunities on our digital nomad job board."
      URL={`${siteMetadata.siteUrl}${DEFAULT_ROUTE_PATHS.JOBBOARD}`}
    />
    <TailwindHeader />
    <TailwindJobBoard />
    <Footer />
    </>
  );
}
