import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import TailwindHeader from '../components/header/TailwindHeader';
import TailwindFeatureSection from '../components/feature/TailwindFeatureSection';
import TailwindNewsLetterSignUp from '../components/sign-up/TailwindNewsletterSignUp';
import { Footer, HelmetComponent } from '../components';
import { siteMetadata } from '../data/siteMetadata';
import { DEFAULT_ROUTE_PATHS } from '../routes/RouteDeclarations';

export default function About() {
  const history = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.set({ page: history.pathname });
    ReactGA.send({ hitType: 'pageview', page: history.pathname, title: 'About Us - Nomad Zen Life' });
}, []);

  return (
    <>
    <HelmetComponent
      title="About Us - Nomad Zen Life"
      description="Learn more about Nomad Zen Life, our mission, and how we support digital nomads in living their best lives on the road."
      URL={`${siteMetadata.siteUrl}${DEFAULT_ROUTE_PATHS.ABOUT}`}
    />
      <TailwindHeader />
      <TailwindFeatureSection />
      <TailwindNewsLetterSignUp forReport />
      <Footer />
    </>
  );
}
