import React from 'react';
import {
 Popover, PopoverButton, PopoverPanel, Transition,
} from '@headlessui/react';
import {
  ChevronDownIcon,
 } from '@heroicons/react/20/solid';
import {
  BriefcaseIcon,
  GlobeAmericasIcon,
  PaperAirplaneIcon,
  CodeBracketIcon,
  CodeBracketSquareIcon,
} from '@heroicons/react/24/outline';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import {
  ReactGAActions, ReactGACategory, RouteToLabelMap,
 } from '../../utils/Constants';

const toolSet = [
  {
    name       : 'AI Travel Guide!',
    description: 'Get personalized hotel recommendations with the AI Travel Guide.',
    href       : 'https://chatgpt.com/g/g-uJJvZK3Wo-traveler-hotel-guide',
    icon       : CodeBracketIcon,
  },
  {
    name: 'NZL Trip Planner',
    description:
      'Plan your next trip with the NZL trip planner tool. Find the best flights, accommodations, and activities for your next adventure.',
    href: '/tools/trip-planner',
    icon: PaperAirplaneIcon,
  },
  {
    name: 'Interactive Map',
    description:
      'Explore some of my previously traveled spots and discover related blog posts.',
    href: '/tools/interactive-travel-map',
    icon: GlobeAmericasIcon,
  },
  {
    name: 'Job Board',
    description:
      'Find your next remote job opportunity with up-to-date opportunities on the NZL job board.',
    href: '/tools/job-board',
    icon: BriefcaseIcon,
  },
];
const callsToAction = [
  { name: 'Seriously - our AI Travel Guide!', href: 'https://chatgpt.com/g/g-uJJvZK3Wo-traveler-hotel-guide', icon: CodeBracketSquareIcon },
];

interface MenuProps {
  name: string;
}

export default function TailwindFlyoutMenu(props: MenuProps) {
  return (
    <Popover className="relative">
      <PopoverButton
      className="inline sm:font-lg font-sm font-semibold text-gray-900"
      style={{ lineHeight: '0.5rem' }}
      >
        <span className="sm:font-lg" style={{ fontSize: '14px', lineHeight: '0rem' }}>{props.name}</span>
        <ChevronDownIcon className="h-2 w-2" aria-hidden="true" />
      </PopoverButton>

      <Transition
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <PopoverPanel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
          <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
            <div className="p-4">
              {toolSet.map((item) => {
 return (
                <div key={item.name} className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                  <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                    <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                  </div>
                  <div>
                    <Link
                    to={item.href}
                    className="font-semibold text-gray-900"
                    onClick={() => {
                      ReactGA.event({
                          category: ReactGACategory.Button,
                          action  : ReactGAActions.Click,
                          label   : RouteToLabelMap[item.href],
                      });
                    }}
                    >
                      {item.name}
                      <span className="absolute inset-0" />
                    </Link>
                    <p className="mt-1 text-gray-600">{item.description}</p>
                  </div>
                </div>
              );
})}
            </div>
            <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
              {callsToAction.map((item) => {
 return (
                <Link
                  key={item.name}
                  to={item.href}
                  className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
                  onClick={() => {
                    ReactGA.event({
                        category: ReactGACategory.Button,
                        action  : ReactGAActions.Click,
                        label   : RouteToLabelMap[item.href],
                    });
                  }}
                >
                  <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                  {item.name}
                </Link>
              );
})}
            </div>
          </div>
        </PopoverPanel>
      </Transition>
    </Popover>
  );
}
