import React, { useEffect, useRef } from 'react';

export default function BudgetCalculator() {
  const scriptContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scriptSrc = 'https://cdn.calconic.com/static/js/calconic.min.js';

    if (scriptContainerRef.current && !scriptContainerRef.current.querySelector(`script[src="${scriptSrc}"]`)) {
      const script = document.createElement('script');
      script.src = scriptSrc;
      script.async = true;
      script.charset = 'utf-8';
      script.dataset.calconic = 'true';
      scriptContainerRef.current.appendChild(script);
    }

    return () => {
      if (scriptContainerRef.current) {
        const existingScript = scriptContainerRef.current.querySelector(`script[src="${scriptSrc}"]`);
        if (existingScript) {
          scriptContainerRef.current.removeChild(existingScript);
        }
      }
    };
  }, []);

  return (
    <div className="relative overflow-hidden bg-white">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-1">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-xlg py-3">
              <div
                className="calconic-calculator"
                ref={scriptContainerRef}
                data-calculatorid="66860f2908dd93002b506369"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
