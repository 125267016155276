import React from 'react';
import { NavLink } from 'react-router-dom';
import { kebabCase } from '../../utils/Utils';

const Tag = ({ text }) => {
    return (
        <NavLink to={`/tags/${kebabCase(text)}`} className="hover:no-underline text-primary-500 hover:text-primary-600 dark:hover:text-primary-400">
            <a
                href={`/tags/${kebabCase(text)}`}
                className="mr-3 text-sm font-medium uppercase text-primary-500 hover:text-primary-600 dark:hover:text-primary-400"
            >
                {text.split(' ').join('-')}
            </a>
        </NavLink>
    );
};

export default Tag;
