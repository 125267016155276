import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import TailwindHeader from '../components/header/TailwindHeader';
import TailwindNewsLetterSignUp from '../components/sign-up/TailwindNewsletterSignUp';
import { Footer, HelmetComponent } from '../components';
import TailwindBlog from '../components/blog/TailwindBlog';
import { siteMetadata } from '../data/siteMetadata';
import { DEFAULT_ROUTE_PATHS } from '../routes/RouteDeclarations';
import { Post } from '../components/blog-post/BlogPostFuncs';

export default function BlogContainer(posts: Post[]) {

  const history = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.set({ page: history.pathname });
    ReactGA.send({ hitType: 'pageview', page: history.pathname, title: 'Nomad Zen Life Blog - Tips and Stories for Digital Nomads' });
}, []);

  return (
    <>
    <HelmetComponent
      title="Nomad Zen Life Blog - Tips and Stories for Digital Nomads"
      description="Read our blog for tips, stories, and inspiration for living a nomadic lifestyle. Stay updated with the latest from the Nomad Zen Life community."
      URL={`${siteMetadata.siteUrl}${DEFAULT_ROUTE_PATHS.BLOG}`}
    />
      <TailwindHeader />
      <TailwindBlog posts={posts} />
      <TailwindNewsLetterSignUp />
      <Footer />
    </>
  );
}
