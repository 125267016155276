import React, { useEffect, useRef } from 'react';

export default function Trips() {
  const scriptContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scriptSrc = 'https://tp.media/content?trs=331827&shmarker=556388&locale=en&curr=USD&powered_by=true&border_radius=0&plain=true&color_button=%232681ff&color_button_text=%23ffffff&color_border=%232681ff&promo_id=4132&campaign_id=121';

    if (scriptContainerRef.current && !scriptContainerRef.current.querySelector(`script[src="${scriptSrc}"]`)) {
      const script = document.createElement('script');
      script.src = scriptSrc;
      script.async = true;
      script.charset = 'utf-8';
      scriptContainerRef.current.appendChild(script);
    }

    return () => {
      if (scriptContainerRef.current) {
        const existingScript = scriptContainerRef.current.querySelector(`script[src="${scriptSrc}"]`);
        if (existingScript) {
          scriptContainerRef.current.removeChild(existingScript);
        }
      }
    };
  }, []);

  return (
    <div className="relative overflow-hidden bg-white">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 lg:min-h-[550px]">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Take Flight With Our Partners
              </p>
              <h2 className="text-base font-semibold leading-7 text-indigo-600">
                Check out some of the latest flight deals
              </h2>
            </div>
          </div>
        </div>
        {/* <div
        ref={scriptContainerRef}
className="z-900 flex trip-dot-com-anchor pb-50"
        />
      </div> */}
      <div
        ref={scriptContainerRef}
className="flex trip-dot-com-anchor z-900"
      />
      </div>
    </div>
  );
}
