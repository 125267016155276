import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import TailwindHeader from '../components/header/TailwindHeader';
import TailwindNewsLetterSignUp from '../components/sign-up/TailwindNewsletterSignUp';
import { Footer, HelmetComponent } from '../components';
import { siteMetadata } from '../data/siteMetadata';
import { DEFAULT_ROUTE_PATHS } from '../routes/RouteDeclarations';
import FeatureGPT from '../components/feature-gpt/FeatureGPT';

export default function GPT() {
  const history = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.set({ page: history.pathname });
    ReactGA.send({ hitType: 'pageview', page: history.pathname, title: 'AI Travel Guide - Nomad Zen Life' });
}, []);

  return (
    <>
    <HelmetComponent
      title="AI Travel Guide - Nomad Zen Life"
      description="Plan your next trip with Nomad Zen Life's AI-powered travel guide. Get personalized recommendations for your next adventure."
      URL={`${siteMetadata.siteUrl}${DEFAULT_ROUTE_PATHS.ABOUT}`}
    />
      <TailwindHeader />
      <FeatureGPT />
      <TailwindNewsLetterSignUp forReport />
      <Footer />
    </>
  );
}
