import React from 'react';
import {
 CurrencyDollarIcon, GlobeAsiaAustraliaIcon, SparklesIcon, FlagIcon,
} from '@heroicons/react/20/solid';
import ReactGA from 'react-ga4';
import {
 ReactGAActions, ReactGALabels, UsefulServices,
} from '../../utils/Constants';

const features = [
  {
    name: 'Travel Smart with AI Guidance.',
    description:
      'Using Nomad Trip Planner, explore destinations worldwide with in-depth insights into local cultures, languages, and traditions. Enhance your travel experience by learning and adapting to new environments, making every trip a memorable adventure.',
    icon: FlagIcon,
  },
  {
    name       : 'Find the Best Deals Effortlessly.',
    description: 'Nomad Trip Planner scours the web to find the best deals on hotels, flights, and activities tailored to your preferences and budget. Ensure you get the most value for your money, allowing you to enjoy high-quality experiences at affordable prices. Maximize your savings and invest in experiences that truly matter.',
    icon       : CurrencyDollarIcon,
  },
  {
    name       : 'Customize Your Itinerary with Ease.',
    description: 'With Nomad Trip Planner, you have the freedom to personalize your travel plans. Whether you prefer a bustling city or a serene beach, a luxurious hotel or a cozy hostel, Nomad Trip Planner helps you create the perfect itinerary. Enjoy the flexibility to travel on your terms, making every trip stress-free and enjoyable.',
    icon       : GlobeAsiaAustraliaIcon,
  },
  {
    name       : 'Stay Informed with Real-Time Updates.',
    description: 'Nomad Trip Planner keeps you updated with real-time information on weather conditions, local events, and travel advisories. Stay informed and make informed decisions to ensure a smooth and enjoyable journey, no matter where you are.',
    icon       : SparklesIcon,
  },
];

export default function FeatureGPT() {
  return (
    <>
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
            <a
          href={UsefulServices.AITravelGuide}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            ReactGA.event({
                category: ReactGALabels.AITravelGuideLink,
                action  : ReactGAActions.Click,
                label   : ReactGALabels.AITravelGuideLink,
            });
          }}
            >
              <h2 className="text-base font-semibold leading-7 text-indigo-600">
                Live the AI Adventure For Free, Now.
              </h2>
            </a
            >
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Plan with Precision
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
              Experience the ease of planning your travels with Nomad Trip Planner, a custom OpenAI GPT designed to help you navigate every aspect of your journey with confidence and precision.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => {
 return (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                );
})}
              </dl>
            </div>
          </div>
          <a
          href={UsefulServices.AITravelGuide}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            ReactGA.event({
                category: ReactGALabels.AITravelGuideLink,
                action  : ReactGAActions.Click,
                label   : ReactGALabels.AITravelGuideLink,
            });
          }}
          >
          <img
            src="/ai-travel-planner.png"
            alt="Product screenshot"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
          </a>
        </div>
      </div>
    </div>
     <div className="bg-white py-24 sm:py-32">
     <div className="mx-auto max-w-7xl px-6 lg:px-8">
       <div className="mx-auto max-w-2xl lg:text-center">
        <a
        href={UsefulServices.AITravelGuide}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          ReactGA.event({
              category: ReactGALabels.AITravelGuideLink,
              action  : ReactGAActions.Click,
              label   : ReactGALabels.AITravelGuideLink,
          });
        }}
        >
         <h2 className="text-base font-semibold leading-7 text-indigo-600">Start Your Journey Today</h2>
        </a>
         <a
         href={UsefulServices.AITravelGuide}
         target="_blank"
         rel="noopener noreferrer"
         onClick={() => {
           ReactGA.event({
               category: ReactGALabels.AITravelGuideLink,
               action  : ReactGAActions.Click,
               label   : ReactGALabels.AITravelGuideLink,
           });
         }}
         >
         <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
         Unleash the Power of AI in Travel Planning
         </p>
         </a>
         <p className="mt-6 text-lg leading-8 text-gray-600">
         Receive expert travel tips, exclusive deals, and personalized recommendations from Nomad Trip Planner, our custom AI GPT, directly to your inbox. Stay inspired with AI-curated stories and insights to make your adventures seamless and unforgettable.
         </p>
       </div>
       <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
         <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
           {features.map((feature) => {
 return (
             <div key={feature.name} className="relative pl-16">
               <dt className="text-base font-semibold leading-7 text-gray-900">
                 <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                   <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                 </div>
                 {feature.name}
               </dt>
               <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
             </div>
           );
})}
         </dl>
       </div>
     </div>
     </div>
    </>
  );
}
