import React from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Post } from '../blog-post/BlogPostFuncs';
import { ReactGAActions, ReactGACategory, ReactGALabels } from '../../utils/Constants';

export interface CTAProps {
  unsplashPhoto?: boolean;
  posts: Post[];
}
export default function TailwindCTA(props: CTAProps) {
  return (
    <div className="bg-white">
           { props.posts.length > 0
            && (
      <div className="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">

          <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
            <svg
              viewBox="0 0 1024 1024"
              className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
              aria-hidden="true"
            >
              <circle
                cx={512}
                cy={512}
                r={512}
                fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
                fillOpacity="0.7"
              />
              <defs>
                <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                  <stop stopColor="#7775D6" />
                  <stop offset={1} stopColor="#E935C1" />
                </radialGradient>
              </defs>
            </svg>
<div className="mx-auto max-w-md lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                {props.posts[0].metadata.title}
                <br />
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                {props.posts[0].metadata.description}
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                <Link
                  to={`/blog/${props.posts[0].slug}`}
                  onClick={() => {
                    const dimensions = { dimension1: `/blog/${props.posts[0].slug}` };
                    ReactGA.event({
                        category: ReactGACategory.Button,
                        action  : ReactGAActions.Click,
                        label   : ReactGALabels.BlogPageLink,
                        ...dimensions,
                    });
                  }}
                  className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                >
                  Read Blog
                </Link>
                <Link
                  to={`/blog/${props.posts[0].slug}`}
                  className="text-sm font-semibold leading-6 text-white"
                  onClick={() => {
                    const dimensions = { dimension1: `/blog/${props.posts[0].slug}` };
                    ReactGA.event({
                        category: ReactGACategory.Button,
                        action  : ReactGAActions.Click,
                        label   : ReactGALabels.BlogPageLink,
                        ...dimensions,
                    });
                  }}
                >
                  Learn more <span aria-hidden="true">→</span>
                </Link>
              </div>
</div>
            <div className="relative mt-16 h-80 lg:mt-8">
              <img
                className="left-0 top-0  max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
                src={
                  props.unsplashPhoto
                    ? props.posts[0].metadata.imagePreviewName
                    : `./${props.posts[0].metadata.imagePreviewName}`
                }
                alt={`${props.posts[0].metadata.imagePreviewName}`}
                width={600}
                height="100 vh"
              />
            </div>
          </div>
      </div>
      )}
    </div>
  );
}
