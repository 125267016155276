import React from 'react';
// import ReactGA from 'react-ga4';
// import { ReactGAEvents } from '../../utils/Constants';

const CustomLink = ({ href, ...rest }) => {
    const isInternalLink = href && href.startsWith('/');
    const isAnchorLink = href && href.startsWith('#');
    const isAmazonLink = href && href.includes('amzn');
    if (isInternalLink) {
        // ReactGA.event({
        //     category: ReactGAEvents.InternalLink,
        //     action  : `Clicked Internal Link ${href}`,
        // });
        return (
            <a href={href}>
                <a {...rest} />
            </a>
        );
    }

    if (isAnchorLink) {
        return <a href={href} {...rest} />;
    }

    if (isAmazonLink) {
        // ReactGA.event({
        //     category: ReactGAEvents.AmazonLink,
        //     action  : `Clicked Amazon Link ${href}`,
        // });
    }

    return (
        <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
                // ReactGA.event({
                //     category: ReactGAEvents.ExternalLink,
                //     action  : `Clicked External Link ${href}`,
                // });
            }}
            href={href}
            {...rest}
        />
    );
};

export default CustomLink;
